
.actor-container .title{
    padding: 0rem 2rem 0 2rem;
  }
  .actors-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 1.5rem;
    padding: 0rem 2rem 0 2rem;
  }
  .priority-number {
    position: absolute;
    top: 10px;     
    left: 10px;  
    background-color: white;
    color: black;
    border-radius: 50%;  /* Make it circular */
    width: 25px;    
    height: 25px;
    font-size: 12px; 
    display: flex;
    border: 1px solid black;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2); 
  }
  .actor-card {
    position: relative;
    cursor: pointer;
    background-color: white;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
    width: 150px;
  }
  
  .actor-card:hover {
    transform: scale(1.05);
  }
  
  .actor-card img {
    width: 100%;
    height: auto;
    border-bottom: 2px solid rgba(0, 0, 0, 0.1);
  }
  
  .actor-info {
    padding: 0.5rem;
    text-align: center;
  }
  
  .actor-info h3 {
    font-size: 1.8rem;
    font-weight: 600;
  }
  
  .actor-info p {
    font-size: 0.9rem;
    color: grey;
  }
  
  /* Navbar */
  .navbar {
    display: flex;
    justify-content: space-between;
    align-items: center; /* Optional - vertically centers the children */
    width: 100%; /* Make sure the parent has enough width */
    padding: 10px;
    justify-content: space-between;
    align-items: center;
    background-color: #000000;
    padding: 1rem 2rem;
    color: white;
    font-size: 16px;
  }
  
  /* Flexbox for logo and title */
  .navbar-logo {
    display: flex;
    align-items: center;
  }
  
  .navbar-logo img {
    height: 30px;
    margin-right: 10px;
  }
  .navbar-filter{
    margin: 0;
    display: flex;
    align-items: center;
    align-content: center;
    width: 100px;
    color: black;
    gap: 10px;
    flex: 1;
  }
  .filter-category{
    color: white
  }
  
  .navbar-logo h1 {
    margin: 0;
    font-size: 1.5rem; 
  }
  
  /* Search bar styling */
  .navbar-search {
    display: flex;
    position: relative;
    width: 200px;
    color: white
  }
  
  /* Style for the input box */
  .navbar-search input {
    padding: 0.5rem 2rem; 
    border: 1px solid #ccc;
    width: 200px; 
    color: #000000; 
  }
  
  /* Search icon inside input */
  .navbar-search i {
    position: absolute; 
    left: 10px;       
    top: 50%;
    transform: translateY(-50%);  
    color: #000000; 
    font-size: 1rem;
    pointer-events: none; 
  }
  
  /* Style for the input on focus */
  .navbar-search input:focus {
    outline: none;
    border-color: #888;
  }
  /* Modal */
  /* Modal Overlay */
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  /* Modal Content */
  .modal-content {
    background-color: white;
    padding: 2rem;
    border-radius: 1px;
    width: 80vw;
    height: 80vh;
    max-width: 90%;
    box-shadow: 0 2px 20px rgba(0, 0, 0, 0.3);
    position: relative;
  }
  
  /* Close button */
  .close-modal {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 2rem;
    cursor: pointer;
  }
  
  /* Actor details section */
  .actor-details {
    text-align: left;
    margin-top: 1rem;
  }
  
  /* Known for movie list */
  .actor-details ul {
    list-style-type: none;
    padding: 0;
  }
  
  .actor-details ul li {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
  }
  
  .known-for-poster {
    width: 50px;
    height: auto;
    margin-right: 10px;
    border-radius: 5px;
  }
  /* Modal body container with flexbox for left and right sides */
  .modal-body {
    display: flex;
    justify-content: space-between;  /* Space between left and right */
    gap: 20px;
  }
  
  /* Left section: Actor name and image */
  .modal-left {
    flex: 1;  /* Takes up equal space */
    margin-top: -30px;
    height: 100%;
  }
  .modal-left img{
    width: auto;
    height: 70vh;
    border-radius: 1px;
    margin-bottom: 1rem;
  }
  .modal-right {
    flex: 1;  /* Takes up equal space */
    text-align: left;
    margin-top: 20px;
  }
  
  .clear-btn {
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    background: none;
    border: none;
    font-size: 1.2rem;
    cursor: pointer;
    color: #888;
  }
  
  .actors-grid.center-single {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 50vh;
  }

  p{
   margin: 0;
   font-size: 14px;
  }

  .watermark-container {
    position: relative;
    display: inline-block;
    pointer-events: none; /* Prevent interaction */
    user-select: none;
  }
  .watermark-container-2{
    position: relative;
    display: inline-block;
    pointer-events: none; /* Prevent interaction */
    user-select: none;
  }
  .watermarked-image {
    width: 100%;
    height: auto;
  }
  
  /* Common watermark style */
  .watermark {
    position: absolute;
    font-size: 20px;
    color: rgba(255, 255, 255, 0.5);
    font-style: italic; /* Sloped font */
    pointer-events: none; /* Prevent interaction */
    z-index: 10;
    user-select: none;
  }
  .watermark2 {
    position: absolute;
    font-size: 50px;
    color: rgba(255, 255, 255, 0.5);
    font-style: italic; /* Sloped font */
    pointer-events: none; /* Prevent interaction */
    z-index: 10;
    user-select: none;
  }
  /* Positioning the multiple watermarks */
  .watermark-top-left {
    top: 10px;
    left: 10px;
  }
  
  .watermark-bottom-right {
    bottom: 10px;
    right: 10px;
  }
  
  .watermark-center {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 30px; /* Larger watermark */
  }
  